.natha-footer {
  background-color: #333333;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  padding-top: 50px;
  color: #fff;

  .natha-footer-logo {
    height: 120px;
  }

  .natha-footer-copyright {
    padding: 20px 0;
  }
  .natha-footer-social {
    padding-bottom: 20px;
    a {
      border: none;
    }
    .natha-footer-facebook {
      padding: 14px 18px;
    }
    .natha-footer-instagram {
      padding: 14px 16px;
    }
  }
}
