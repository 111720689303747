.main-navbar {
  background-color: #a69c97;
  .container {
    margin: auto;
  }
  #natha-main-nav {
    background-color: transparent;
    border: none;
    .flex-fill {
      display: flex;
      align-items: center;
      text-align: center;
      justify-content: center;
    }
    ul {
      display: flex;
      align-items: center;
      text-align: center;
      justify-content: center;
    }
    ul li {
      font-size: 15px;
      text-transform: uppercase;
      padding: 0 5px;
      a {
        color: #fff;
        // font-weight: 600;
        font-weight: bold;
        font-family: "Battambang", "Open Sans", serif !important;
        // font-family: "Manrope ExtraBold", "Battambang", serif !important;
      }
      a:hover {
        color: #000;
      }
    }
  }
}
