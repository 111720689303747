.natha-header {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  text-align: center;
  padding: 30px 0;
}

.natha-font {
  font-size: 15px;
  font-weight: bold;
  text-transform: uppercase;
  font-family: "Manrope ExtraBold", "Battambang", serif !important;
}

.natha-header-mobile-drawer .ant-drawer-body {
  padding: 60px 0 0 0 !important;
}

.natha-header-mobile {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  text-align: center;
  padding: 5px 0;
  div {
    width: 33%;
  }
}

.natha-header div {
  width: 33%;
}

// all
.natha-btn {
  padding: 8px 12px;
  border-radius: 50%;
  color: inherit;
  margin: 0 5px;
}

.natha-blank-btn,
.natha-blank-btn:hover {
  border: 2px solid #a69c97;
  color: #a69c97;
}

.natha-bg-btn,
.natha-bg-btn:hover {
  border: 2px solid #a69c97;
  background-color: #a69c97;
  color: #fff;
}

.natha-borderless-btn {
  color: #fff;
}

.natha-social-color {
  color: #a69c97;
  font-weight: 400 !important;
}

.natha-phone,
.natha-phone:hover {
  color: #a69c97;
  font-size: 30px !important;
  font-weight: 900 !important;
  letter-spacing: 0 !important;
}

// contact

.natha-contact {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}

// logo

.natha-logo {
  padding: 0;
  margin: 0;
  img {
    height: 70px;
  }
}
.natha-logo-mobile {
  padding: 0;
  margin: 0;
  img {
    height: 50px;
  }
}

// setting
.natha-setting {
  padding-left: 10px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  a {
    color: #a69c97;
    font-weight: bold;
  }
}

.natha-language-selector,
.natha-language-selector:hover {
  color: #000;
  .anticon {
    position: relative;
    top: -2px;
    margin-left: 1px;
  }
}
