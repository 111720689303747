.natha-page-interior {
  margin-top: 60px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.interior-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.interior-title,
.interior-slogan {
  font-family: "Manrope ExtraBold", "Battambang", serif !important;
}

.interior-title {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
  line-height: 1.2em;
  font-size: 40px;
  color: #181b31;
}

.interior-slogan {
  line-height: 1.2em;
  font-size: 18px;
  color: #181b31;
}

.interior-description {
  max-width: 800px;
  margin-top: 20px;
  font-weight: 400;
  line-height: 1.7em;
  font-size: 16px;
  color: #333333;
}

.natha-page-boulevard {
  background-color: #a89c96;
}
.natha-page-wood {
  background-color: #333333;
}

.interior-video {
  margin-top: 30px;
  max-width: 1200px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-bottom: 50px;
  img {
    width: 100%;
    margin: auto;
  }
}

/// the woods
.natha-page-wood {
  min-height: auto !important;
}

.natha-thewood-image {
  max-width: 1400px;
  overflow: hidden;
}

.natha-thewood-content {
  padding: 0 60px;
  color: #fff;
}

.natha-thewood-title,
.natha-thewood-slogan {
  color: #fff;
  font-family: "Manrope ExtraBold", "Battambang", serif !important;
}

.natha-thewood-title {
  margin-top: 30px;
  line-height: 1.2em;
  font-size: 40px;
}
.natha-thewood-slogan {
  line-height: 1.2em;
  font-size: 18px;
}

.natha-thewood-description {
  max-width: 800px;
  margin-top: 20px;
  font-weight: 400;
  line-height: 1.7em;
  font-size: 16px;
}

// boulevard
.natha-page-boulevard {
  min-height: auto !important;
  background-color: #a89c96 !important;
}

.class-test {
  position: relative;
}

.interior-video-player {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  span {
    color: #eb2f96;
    font-size: 64px;
  }
}
