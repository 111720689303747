.natha-banner {
  background-position: center bottom !important;

  padding: 50px 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  box-sizing: border-box;
  position: relative;
}

.liquid-row-overlay {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute !important;
  top: 0;
  left: 0;
  transition: background 0.3s, opacity 0.3s;
  background: rgb(84, 86, 90);
  background: -webkit-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0,
    #34493f 66%
  ) !important;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(0, 0, 0, 0)),
    to(#34493f)
  ) !important;
  background: -moz-
    oldlinear-gradient(top, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0.6) 66%) !important;
  background: linear-gradient(
    to top,
    rgba(0, 0, 0, 0) 0,
    #34493f 66%
  ) !important;
}

.banner-text-content {
  z-index: 1;
  text-align: center;
  margin: auto;
  width: 66.66666667%;
  color: #fff;
  h2,
  h3,
  p {
    color: #fff;
  }
  p {
    max-width: 770px;
    margin: auto;
    margin-top: 40px;
  }
}

.banner-title {
  position: relative;
  margin: 0;
  line-height: 1.2em;
  font-size: 40px;
  font-family: "Manrope ExtraBold", "Battambang", serif !important;
}

.banner-slogan {
  line-height: 1.2em;
  font-size: 18px;
  font-family: "Manrope ExtraBold", "Battambang", serif !important;
}

.banner-description {
  font-family: "Battambang", "Open Sans", serif !important;
  font-weight: 400;
  line-height: 1.7em;
  font-size: 16px;
}
